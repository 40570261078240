*,
*:before,
*:after {
  box-sizing: border-box;
  line-height: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
  font-size: 16px;
  background-color: #fff;
  font-family: 'General Sans', sans-serif;

  @media (min-height: 602px) and (min-width: 602px) {
    background-color: #F8F8FA;
  }
}

body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  font-family: inherit;
  color: var(--base);
  min-height: 100%;
  background-color: inherit;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  text-decoration-skip: objects;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

p,
dd,
dl,
figure,
blockquote {
  margin: 0;
}

p {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

blockquote,
q {
  quotes: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  word-break: break-word;
}

audio,
video {
  display: block;
}

img {
  display: inline-block;
  border: none;
  max-width: 100%;
  width: 100%;
  height: auto;
}

iframe {
  border: none;
}

pre,
code,
kbd,
samp {
  font-family: inherit;
  font-size: inherit;
  white-space: pre-line;
  margin: 0;
}

a {
  display: inline-block;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.25s ease-in;

  &::before,
  &::after {
    transition: inherit;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

abbr {
  border: none;
  text-decoration: none;
}

b,
strong {
  font-weight: 700;
}

i,
em {
  font-style: italic;
}

dfn {
  font-style: inherit;
}

mark {
  background-color: transparent;
  color: inherit;
}

small {
  font-size: inherit;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: inherit;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  color: inherit;
  letter-spacing: inherit;
  transition: all 0.25s ease-in;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-align: left;
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
}

textarea {
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: none;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[type="search"] {
  outline: none;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

legend {
  display: block;
  padding: 0;
  white-space: normal;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

::-moz-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

:-moz-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

:focus {
  outline: none;
}

:focus::-webkit-input-placeholder {
  opacity: 0;
}

:focus::-moz-placeholder {
  opacity: 0;
}

:focus:-moz-placeholder {
  opacity: 0;
}

:focus:-ms-input-placeholder {
  opacity: 0;
}

[hidden] {
  display: none;
}

:disabled,
.disabled {
  cursor: not-allowed;
}

::-ms-clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--grey) inset;
}

::selection {
  color: #fff;
  background-color: var(--base);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}