:root {
  --base: #111111;
  --white: #ffffff;

  --container: 570px;
  --padding: 16px;
}

@import "./scss/_fonts.scss";
@import "./scss/_reset.scss";
@import "./scss/_form.scss";
@import "./scss/_select.scss";

.__container {
  width: 100%;
  padding: 32px var(--padding);
  max-width: var(--container);
  margin: auto;
  background-color: var(--white);
  font-family: "Roboto";

  @media (min-width: 602px) {
    --padding: 48px;
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    border: 1px solid #dbdbdb;
    border-radius: 30px;
  }
}

.placeholder {
  min-height: 200px;
  font-size: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade {
  animation: fade-in-keyframes 1s;
}
@keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
