.form {

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  &__fieldset {
    display: grid;
    justify-content: flex-start;
    gap: 8px;

    &_select {
      position: relative;
      z-index: 2;
    }
    &:not(:first-of-type) {
      margin-top: 32px;
      opacity: 0.3;
      transition: all 0.25s linear;

      .selected & {
        opacity: 1;
      }
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-family: "Roboto";
    font-weight: 500;
    color: #8e8e93;
  }

  &__select {
    position: relative;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #5856d6;
    padding-right: 28px;
    transition: all 0.25s linear;

    .selected & {
      color: #5856d6;
      font-weight: 700;
    }

    .selected &-loading {
      color: #ccc;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-image: url("../images/arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
    }
  }

  &__card {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #8e8e93;
    transition: all 0.25s linear;

    .selected & {
      color: #000;
      font-weight: 700;
    }
  }

  &__alert {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background: rgba(255, 149, 0, 0.15);
    border-radius: 12px;
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &::before {
      content: "";
      display: block;
      background-image: url("../images/alert.svg");
      background-repeat: no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
      flex: 0 0 auto;
    }
  }

  &__submit {
    display: block;
    width: 100%;
    text-align: center;
    padding: 16px;
    background: rgba(142, 142, 147, 0.12);
    color: rgba(142, 142, 147, 0.5);
    border-radius: 28px;
    margin-top: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    .selected & {
      background-color: #5856d6;
      color: #fff;
    }

    .selected &-loading {
      background-color: rgba(142, 142, 147, 0.12);
      color: rgba(142, 142, 147, 0.5) ;
      cursor: progress;
    }
  }
}

.note,
.amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #5856d6;

  &__copy {
    margin-left: 40px;
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 4px;

    &::before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      flex: 0 0 auto;
      background-image: url("../images/copy.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.amount {
  &__number {
    font-size: 24px;
    line-height: 1.25;

    @media (min-width: 602px) {
      font-size: 32px;
    }
  }
}

.footer-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;

  &__time {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;

    color: #8e8e93;

    i {
      font-style: normal;
      font-weight: 700;
      color: #000;
    }
  }

  &__cancel {
    font-weight: 700;
    color: #8e8e93;
  }
}
