:root {
  --base: #111111;
  --white: #ffffff;

  --container: 570px;
  --padding: 16px;
}

@import "./fonts";
@import "./reset";

.__container {
  width: 100%;
  padding: 32px var(--padding);
  max-width: var(--container);
  margin: auto;
  background-color: var(--white);

  @media (min-width: 602px) {
    --padding: 48px;
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    border: 1px solid #dbdbdb;
    border-radius: 30px;
  }
}

.alert {
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  min-height: 450px;

  &__img {
    display: block;
    margin: 0 auto 24px;
    width: 128px;
    height: 128px;

    .alert_loading & {
      width: 180px;
      height: 180px;
      animation: rotate 5s linear infinite;

      @media (min-width: 480px) {
        width: 256px;
        height: 256px;
      }
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-weight: 500;
    color: #8e8e93;
  }

  &__link {
    max-width: 240px;
    width: 100%;
    margin: 48px auto 0;
    color: #fff;
    padding: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    background: #5856d6;
    border-radius: 28px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.redirectTimer {
  text-align: center;
  color: #8e8e93;

  &__link {
    font-weight: 700;
    text-decoration: underline;
  }
}
